import { create } from 'zustand';
import api from "../../Components/Axios/api";

const useUserCardStore = create((set) => ({
  cartItems: [],
  loading: false,
  error: null,

  setCartItems: (value) => set({ cartItems: value }),

  getCartItems: async (userId) => {
    set({ loading: true, error: null });
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/cart/getcart/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("Cart Response:", response.data);
      set({ cartItems: response.data.data });
      return response;

    } catch (err) {
      set({ error: "No cart item" });
      console.error(err);
    } finally {
      set({ loading: false });
    }
  },

  deleteFromCart: async (productId) => {
    set({ loading: true, error: null });
    const token = localStorage.getItem('token');
    try {
      const response = await api.delete(`/cart/delete/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set({ loading: false });

      return response;
    } catch (err) {
      set({ error: "Failed to remove item from cart", loading: false });
      console.error("Error during deleteFromCart:", err);
    }
  },

  addToCart: async (payload) => {
    set({ loading: true, error: null });
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        set({ error: "No authentication token found" });
        return;
      }

      const response = await api.post("/cart/create", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set({ cartItems: response.data.data });
      return response.data;
    } catch (err) {
      set({ error: "Failed to add item to cart" });
      console.error(err);
    } finally {
      set({ loading: false });
    }

  }
}));

export default useUserCardStore;
