import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import teamimg from "./Assets/teamimg.jpg";
import "../styles/ourteam.css";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

import teamimg1 from "../Components/Assets/Profile1.png";

function Ourteam() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  return (
    <>
      <div className="our_team_section">
        <div className="container">
          <h1>Our Team</h1>
          <p>
            At Prabhu Pooja, our dedicated teams work together to deliver the
            best spiritual services and customer experience. Our teams include
            Client Coordination, Artisan, Packaging and Handling, Dispatch, Web
            Designing & Development, SEO & Content Creation, Accounts, and
            Support Staff. Each team is committed to ensuring seamless service,
            from high-quality spiritual products to expert-guided rituals,
            providing a divine and fulfilling experience for our valued
            customers.
          </p>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="our-team">
                <div className="picture">
                  <img className="img-fluid" src={teamimg1} />
                </div>
                <div className="team-content">
                  <h3 className="name">Rishi</h3>
                  <h4 className="title">Web Developer</h4>
                </div>
                <ul className="social">
                  <li>
                    <FaFacebookF className="icon" />
                  </li>
                  <li>
                    <GrInstagram className="icon" />
                  </li>
                  <li>
                    <FaLinkedinIn className="icon" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="our-team">
                <div className="picture">
                  <img
                    className="img-fluid"
                    src="https://picsum.photos/130/130?image=839"
                  />
                </div>
                <div className="team-content">
                  <h3 className="name">Patricia Knott</h3>
                  <h4 className="title">Web Developer</h4>
                </div>
                <ul className="social">
                  <li>
                    <FaFacebookF className="icon" />
                  </li>
                  <li>
                    <GrInstagram className="icon" />
                  </li>
                  <li>
                    <FaLinkedinIn className="icon" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="our-team">
                <div className="picture">
                  <img
                    className="img-fluid"
                    src="https://picsum.photos/130/130?image=856"
                  />
                </div>
                <div className="team-content">
                  <h3 className="name">Justin Ramos</h3>
                  <h4 className="title">Web Developer</h4>
                </div>
                <ul className="social">
                  <li>
                    <FaFacebookF className="icon" />
                  </li>
                  <li>
                    <GrInstagram className="icon" />
                  </li>
                  <li>
                    <FaLinkedinIn className="icon" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="our-team">
                <div className="picture">
                  <img
                    className="img-fluid"
                    src="https://picsum.photos/130/130?image=836"
                  />
                </div>
                <div className="team-content">
                  <h3 className="name">Mary Huntley</h3>
                  <h4 className="title">Web Developer</h4>
                </div>
                <ul className="social">
                  <li>
                    <FaFacebookF className="icon" />
                  </li>
                  <li>
                    <GrInstagram className="icon" />
                  </li>
                  <li>
                    <FaLinkedinIn className="icon" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ourteam;
