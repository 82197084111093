import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/muhurat.css";
import CryptoJS from "crypto-js";

import { TailSpin } from "react-loader-spinner";
import useMuhuratStore from "../../Store/MuhuratStore/MuhuratStore";

const Muhurat = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { muhuratGet, muhurat } = useMuhuratStore();

  useEffect(() => {
    const fetchMuhurats = async () => {
      try {
        await muhuratGet();
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch muhurat data");
        setLoading(false);
      }
    };
    fetchMuhurats();
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const encryptId = (ID) => {
    const encrypted = CryptoJS.AES.encrypt(
      ID.toString(),
      "prabhupooja2024"
    ).toString();
    return encodeURIComponent(encrypted);
  };


  return (
    <>
      <div className="sub_header_muhurat">
        <div className="container">
          <div className="subheader_inner_muhurat">
            <div className="subheader_text_muhurat">
              <h1>Muhurat</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Muhurat</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="muhurat-list">
        <div className="container">
          <div className="row">
            {/* Map through the fetched muhurats data and display them dynamically */}
            {muhurat.map((muhur, index) => {

              const encryptedId = encryptId(muhur.id)
              return(
              <div key={index} className="col-md-4 col-lg-3">
                <div className="muhurat-img-box">
                  <Link to={`/panditmuhuratprofile/${encryptedId}`}>
                    <img src={muhur.image} alt={muhur.name} />
                    <p>{muhur.name}</p>
                  </Link>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
    </>
  );
};

export default Muhurat;
