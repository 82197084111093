import React, { useState } from "react";
import "../../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import logoImg from "../Assets/logo_Prabhupooja-removebg.png";

const Login = ({ onCloseLogin, onOpenOtp, onOpenSignup, setLoginInput }) => {
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputError, setInputError] = useState("");

  const { login, isLoading, setIsLoading } = useAuthStore();

  const validateInput = () => {
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!mobileRegex.test(input) && !emailRegex.test(input)) {
      setInputError(
        "Please enter a valid 10-digit mobile number or email address"
      );
    } else {
      setInputError("");
    }
  };

  const navigate = useNavigate();



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   validateInput();
  //   if (inputError) {
  //     return;
  //   }

  //   try {
  //     const response = await api.post("/users/login", {
  //       input: input,
  //     });
  //     console.log(input);
  //     console.log("User data:", response.data);
  //     if (response.data.token) {
  //       localStorage.setItem("token", JSON.stringify(response.data.token));
  //       localStorage.setItem("users", JSON.stringify(response.data.user));
  //       localStorage.setItem("member", JSON.stringify(response.data.member));
  //       navigate("/otp");
  //     }
  //     console.log("Login success:", response.data);
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //     if (error.response && error.response.data) {
  //       setErrorMessage(error.response.data.message);
  //     } else {
  //       setErrorMessage("Login failed. Please try again later.");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    validateInput();
    if (inputError) {
      setIsLoading(false);
      return;
    }
    if (!input) {
      setInputError(
        "Please enter a valid 10-digit mobile number or email address"
      );
      setIsLoading(false);
    } else {
      try {
        const response = await login({ input });

        setLoginInput(input);

        if (!response || !response.data) {
          throw new Error("Invalid response format");
        }

        // Save the necessary data to localStorage
        // localStorage.setItem("users", JSON.stringify(response.data.user));
        // localStorage.setItem("member", JSON.stringify(response.member));
        // navigate("/otp");

        onOpenOtp();

        console.log("Login success:", response);
      } catch (error) {
        console.error("Login failed:", error);

        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Login failed. Please try again later.");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="overlaypop">
        <div className="loginPopup">
          <div className="addUser">
            <div className="closeBtn" onClick={onCloseLogin}>
              <span className="ic--baseline-close" title="close"></span>
            </div>
            <div className="loginLogoImg">
              <img src={logoImg} alt="logo" />
            </div>
            <h3>Welcome Back !</h3>
            <form className="addUserForm" onSubmit={handleSubmit}>
              <div className="inputGroup">
                <label htmlFor="input">Mobile Number or Email</label>
                <div className="inputGroup1">
                  <input
                    type="text"
                    id="input"
                    autoComplete="off"
                    placeholder="Enter your Mobile Number or Email"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onBlur={validateInput}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary login-button"
                  >
                    {isLoading ? "Sending..." : "Send Otp"}
                  </button>
                  {/* <span onClick={onOpenOtp}>open</span> */}
                </div>
                {inputError && <p className="error">{inputError}</p>}
                {errorMessage && <p>{errorMessage}</p>}
              </div>

              <div className="login">
                <p>Don't have an Account? </p>
                <Link className="btn btn-success" onClick={onOpenSignup}>
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
