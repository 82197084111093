import React, { useState, useEffect } from "react";
import "../../styles/blogs.css";
import { Link, useParams } from "react-router-dom";
import useHomeStore from "../../Store/dataStore/homeStore";
import { FaComment } from "react-icons/fa";
import { GoHeartFill } from "react-icons/go";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

function Blogs() {
  const {
    getOneBlogs,
    isLoading,
    getRecomendetionBlogs,
    likeBlog,
    Postcomment,
    getComment,
    error,
  } = useHomeStore();
  const { id, title } = useParams();
  const [blog, setBlog] = useState("");
  const [reletedBlog, setReletedBlog] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommnetCount] = useState(0);
  const [getComments, setGetComments] = useState(0);
  const { user1 } = useAuthStore();
  const [showAll, setShowAll] = useState(false);
  const commentsArray = Array.isArray(getComments) ? getComments : [];
  const displayedComments = showAll ? commentsArray : commentsArray.slice(0, 3);
  const [expandedComments, setExpandedComments] = useState({});
  const maxLength = 100;

  const toggleReadMore = (index) => {
    setExpandedComments((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleCommentSubmit = async (id) => {
    const finalName = name || user1?.name || "";
    const finalEmail = email || user1?.email || "";
    if (!finalName || !finalEmail || !comment) {
      Swal.fire({
        icon: "warning",
        title: "Missing Fields!",
        text: "Please fill in all fields before submitting.",
      });
      return;
    }

    const payload = { name: finalName, email: finalEmail, comment };
    try {
      const response = await Postcomment(payload, decryptId(id));
      if (response.data.success) {
        setName("");
        setEmail("");
        setComment("");

        Swal.fire({
          icon: "success",
          title: "Comment Submitted!",
          text: "Your comment has been successfully added.",
        });
        fetchCommnets();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: response?.data?.message || "Something went wrong!",
        });
      }
    } catch (er) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error || "An error occurred while submitting the comment.",
      });
    }
  };

  const fetchCommnets = async () => {
    const response = await getComment(decryptId(id));

    // console.log(response.data.data.comments, 'dkfjd;');
    setGetComments(response.data.data.comments);
    setCommnetCount(response.data.data.total_comments);
  };

  const decryptId = (encryptedIdFromUrl) => {
    const decodedId = decodeURIComponent(encryptedIdFromUrl);
    const bytes = CryptoJS.AES.decrypt(decodedId, "prabhupooja2024");
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    fetchOneBlog();
    fetchRecomedtionBlog();
    fetchCommnets();
  }, []);

  const fetchOneBlog = async () => {
    const response = await getOneBlogs(decryptId(id));
    if (response.data.success) {
      setBlog(response.data.blog);
      setLikeCount(response.data.blog.like_count);
    }
  };

  const fetchRecomedtionBlog = async () => {
    const response = await getRecomendetionBlogs(title);
    if (response.data.success) {
      setReletedBlog(response.data.blogs);
    }
  };

  function formatText(text) {
    return text.split("\n").map((line, index) => <p key={index}>{line}</p>);
  }

  const handlelike = async (id) => {
    const response = await likeBlog(decryptId(id));
    if (response.data.success) {
      fetchOneBlog();
    }
  };

  const encryptId = (blogId) => {
    const encrypted = CryptoJS.AES.encrypt(
      blogId.toString(),
      "prabhupooja2024"
    ).toString();
    return encodeURIComponent(encrypted);
  };

  return (
    <>
      <div className="blogs_container">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="blog_main_title">{blog.title || ""}</h1>
              <p className="blog_main_para">{blog.intro}</p>
              <div className="blogimg-container">
                {blog.paragraphs?.map((paragraph, index) => (
                  <div key={index} className="paragraph-item">
                    {paragraph.paraImage ? (
                      <img
                        src={paragraph.paraImage}
                        alt={`blogimg-${index}`}
                        className="blog_img"
                      />
                    ) : (
                      <div className="empty-img-placeholder"></div>
                    )}
                    <h2
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        margin: "25px 0 10px  0",
                      }}
                    >
                      {paragraph.paraTitle}
                    </h2>
                    <p className="subtitle_para">{paragraph.paraDescription}</p>
                  </div>
                ))}
              </div>

              <div className="article-comment-like">
                <div className="comment-section">
                  <h2 className="comment_text">Leave a Reply</h2>
                  <p className="comment-submit">
                    Your email address will not be published!
                  </p>

                  {/* Comment Textarea */}
                  <textarea
                    placeholder="Write a comment..."
                    rows="4"
                    cols="50"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="blog_comment_section"
                    required
                  />

                  {/* Input Fields for Name and Email */}
                  <div className="comment-post-box">
                    <div className="comment-input-group">
                      <label>Name *</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        value={name || user1?.name}
                        onChange={(e) => setName(e.target.value)}
                        className="comment_input"
                        required
                      />
                    </div>

                    <div className="comment-input-group">
                      <label>Email *</label>
                      <input
                        type="email"
                        placeholder="Your Email"
                        value={email || user1?.email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="comment_input"
                        required
                      />
                    </div>
                  </div>

                  {/* Post Comment Button */}
                  <button
                    className="post_comment_btn"
                    onClick={() => handleCommentSubmit(id)}
                  >
                    {isLoading ? "Posting.." : "Post Comment"}
                  </button>
                </div>

                {/* Comments and Likes Section */}
                <div className="comment-like-section">
                  {/* Comments Count */}
                  <div className="comment-like-header">
                    <div className="comment-header">
                      <h2 className="comment-text_main">
                        ({commentCount}) Comments <FaComment className="icon" />
                      </h2>
                    </div>
                    <div className="like-header" onClick={() => handlelike(id)}>
                      <h2 className="comment-text_main">
                        ({likeCount}) Likes{" "}
                        <GoHeartFill className="icon like-icon" />
                      </h2>
                    </div>
                  </div>
                  {/* Comment List */}

                  <div className="comment-list-container">
                    <h3 className="list-title">All Comments</h3>
                    <div className="comment-list">
                      {Array.isArray(getComments) && getComments.length > 0 ? (
                        displayedComments.map((comment, index) => (
                          <div key={index} className="single-comment">
                            <h4 className="commenter-name">{comment.name}</h4>
                            <p className="comment-text">
                              {expandedComments[index] || comment.comment.length <= maxLength
                                ? comment.comment
                                : `${comment.comment.slice(0, maxLength)}...`}
                            </p>
                            {comment.comment.length > maxLength && (
                              <button
                                onClick={() => toggleReadMore(index)}
                                className="read-more-btn"
                              >
                                {expandedComments[index] ? (
                                  <IoIosArrowUp className="upicon" />
                                ) : (
                                  <IoIosArrowDown className="downicon" />
                                )}
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <p className="no-comments">No comments available</p>
                      )}
                      {getComments.length > 3 && (
                        <button
                          className="toggle-btn"
                          onClick={() => setShowAll(!showAll)}
                        >
                          {showAll ? (
                            <IoIosArrowUp className="upicon" />
                          ) : (
                            <IoIosArrowDown className="downicon" />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Recommended Articles Section */}
            <div className="col-sm-4">
              <div className="articles">
                <h1>Recommended articles</h1>
                {reletedBlog.map((article, index) => {
                  const encryptedId = encryptId(article.id);
                  return (
                    <div key={index}>
                      <Link
                        to={`/blog/${article.title.replace(
                          /\s+/g,
                          "-"
                        )}/${encryptedId}`}
                        className="articles_box"
                      >
                        <div className="articles_img">
                          <img
                            src={article.paragraphs[0].paraImage}
                            alt="blogimg"
                          />
                        </div>
                        <div className="articles_content">
                          <h2>{article.title}</h2>
                          <p>
                            {article.intro.split(" ").slice(0, 15).join(" ")}...
                          </p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Related Blogs Section */}
      <div className="related_blogs">
        <div className="container">
          <h1 className="related_blog_main_title">Related Blogs</h1>
          <div className="row">
            {reletedBlog?.map((article, index) => {
              const encryptedId = encryptId(article.id);
              return (
                <div className="col-sm-4" key={index}>
                  <Link
                    to={`/blog/${article.title.replace(
                      /\s+/g,
                      "-"
                    )}/${encryptedId}`}
                  >
                    <div className="blogs_box">
                      <img
                        src={article.paragraphs[0].paraImage}
                        alt="blog_img"
                        className="related_blog_img"
                      />
                      <h2 className="related_blog_title">{article.title}</h2>
                      <p className="related_blog_para">
                        {article.intro.split(" ").slice(0, 40).join(" ")}...
                      </p>
                      <button className="related_blog_btn">Read More</button>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blogs;
