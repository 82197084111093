import { create } from "zustand";
import api from '../../Components/Axios/api';


const usePanditStore = create ((set)=>({
    Pandit:[],
    PanditId:[],

    PanditGet: async()=>{
        try {
            const response = await api.get("/pandit/pandit");
           
            if (response.data.success) {
             set({Pandit:response.data.data})
            }
          } catch (error) {
            console.error("Error fetching services:", error);
            throw error;
          }
    },
    PanditGetById: async(panditid)=>{
        try {
            const response = await api.get(`/pandit/id/${panditid}`);
           
            if (response.data.success) {
             set({PanditId:response.data.data})
            }
          } catch (error) {
            console.error("Error fetching services:", error);
            throw error;
          }
    }
}))
export default usePanditStore;