import React, { useEffect, useState } from "react";
import "../../styles/blog.css";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import useHomeStore from "../../Store/dataStore/homeStore";
import moment from "moment";
import CryptoJS from "crypto-js";

function truncateText(text, wordLimit) {
  if (!text) return "";
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}

function Blog() {
  // const [blogPosts, setBlogPosts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const { blogs, loading, getBlogs } = useHomeStore();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    await getBlogs();
  };

  const encryptId = (blogId) => {
    const encrypted = CryptoJS.AES.encrypt(
      blogId.toString(),
      "prabhupooja2024"
    ).toString();
    return encodeURIComponent(encrypted);
  };

  // useEffect(() => {
  //     const fetchBlogs = async () => {
  //         try {
  //             const response = await api.get('/blog/get');
  //             const data = response.data;
  //             console.log(data);
  //             if (data.success) {
  //                 setBlogPosts(data.blogs);
  //             } else {
  //                 console.error('Failed to fetch blogs:', data.message);
  //             }
  //         } catch (error) {
  //             console.error('Error fetching blogs:', error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };

  //     fetchBlogs();
  // }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  return (
    <>
      <div className="sub_header_blog">
        <div className="container">
          <div className="subheader_inner_blog">
            <div className="subheader_text_blog">
              <h1>Spiritual - Blogs</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Blogs</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="blog-container">
        {/* <header className="blog-header1">
                    <h1 className="blog-title1">Spiritual Blogs</h1>
                    <p className="blog-subtitle1">Your guide to spirituality and rituals</p>
                </header> */}

        <div className="blog-layout">
          {blogs?.map((post) => {
            let firstImage = post.paragraphs[0].paraImage;

            const encryptedId = encryptId(post.id);

            return (
              <div className="post-card" key={post.id}>
                <Link
                  to={`/blog/${post.title.replace(/\s+/g, "-")}/${encryptedId}`}
                >
                  <div
                    className="post-image"
                    style={{
                      backgroundImage: `url(${
                        firstImage || "default-image-url.jpg"
                      })`,
                    }}
                  ></div>
                </Link>
                <div className="post-content">
                  <h2 className="post-title">
                    {post.title || "Untitled Blog"}
                  </h2>
                  <p className="post-description">
                    {truncateText(post.intro, 30)}
                  </p>
                  <div className="author_name_time">
                    <p>By: Prabhu Pooja</p>
                    <p className="blog_time">
                      {moment(post.created_at).fromNow()}
                    </p>
                  </div>

                  <Link
                    to={`/blog/${post.title.replace(
                      /\s+/g,
                      "-"
                    )}/${encryptedId}`}
                    className="read-more1"
                  >
                    <span>Read More</span>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Blog;
