import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/cart.css";
import prasadimg from "../Components/Assets/prasadimg.webp";
import api from "./Axios/api";
import useUserCardStore from "../Store/userCardStore/userCardStore";
import useAuthStore from "../Store/UserStore/userAuthStore";
import { TailSpin } from "react-loader-spinner";
import { IoCartOutline } from "react-icons/io5";

const Cart = () => {
  // const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();
  const { loading, deleteFromCart, cartItems, getCartItems, setCartItems } =
    useUserCardStore();
  const { user1, isLoggin } = useAuthStore();

  const handleRemoveItem = async (id) => {
    try {
      await deleteFromCart(id);
      console.log("removed");
      await getCartItems(user1.id);
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };


  const handleIncreaseQuantity = async (id) => {
    console.log('chal rh hu');
    const token = localStorage.getItem('token');
    try {
       const response = await api.post(
        "/cart/update-quantity",
        {
          user_id: user1?.id,
          productId: id,
          action: "increment",
        }, 
        {
          headers: {
            Authorization:`Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const updatedItems = cartItems.map((item) =>
          item.productId === id ? { ...item, quantity: response.data.quantity } : item
        );
        setCartItems(updatedItems);
      }
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };
  const handleDecreaseQuantity = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.post(
        "/cart/update-quantity",
        {
          user_id: user1?.id,
          productId: id,
          action: "decrement",
        }, 
        {
          headers: {
            Authorization:`Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) { 
        const updatedItems = cartItems.map((item) =>
          item.productId === id
            ? { ...item, quantity: response.data.quantity } 
            : item
        );
  
        // Remove items with zero quantity
        setCartItems(updatedItems.filter((item) => item.quantity > 0));
      }
    } catch (error) {
      console.error("Error decreasing quantity:", error);
    }
  };

  const handleCheckout = () => {
    const productId = cartItems?.map((item) => item.productId);
    const quantity = cartItems?.map((item) => item.quantity);
    const image = cartItems?.map((item) => item.image);
    console.log(image);
    const totalPrice = cartItems.reduce(
      (total, item) => total + item.offerPrice * item.quantity,
      0
    ); // Calculate total price
    const productData = cartItems?.map((item) => ({
      productId: item.productId,
      productName: item.productName,
      price: item.offerPrice,
      quantity: item.quantity,
      totalPrice: item.offerPrice * item.quantity,
      image: item.image,
    }));
    console.log(productData);
    navigate("/checkout", {
      state: {
        productId: productId,
        quantity: quantity,
        totalPrice: totalPrice,
        user: user,
        booking: "cart",
        images: image,
      },
    });
  };

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="cart-page">
      {cartItems?.length === 0 ? (
        <>
          <div className="empty-container">
            <IoCartOutline className="empty-icon" />
            <h2 className="empty-text">Your cart is empty!</h2>
            <p className="empty-subtext">
              Looks like you haven’t added anything to your cart yet.
            </p>
            <Link to="/e-commerce" className="continue-shopping">
              Continue
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="main-cart-container">
            <div className="cart-items1">
              {cartItems &&
                cartItems?.map((item, index) => (
                  <div key={index} className="cart-item1">
                    <img src={item.image || prasadimg} alt={item.productName} />
                    <div className="cart-item-details1">
                      <h2>{item.productName}</h2>
                      <p>Price: ₹{item.offerPrice}</p>
                      <div className="quantity-control">
                        <button
                          onClick={() => handleDecreaseQuantity(item.productId)}
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={() => handleIncreaseQuantity(item.productId)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="remove-button"
                    >
                      Remove
                    </button>
                  </div>
                ))}
            </div>
            <div className="order-summary">
              <h2>Order Summary</h2>
              <div className="summary-itemm">
                <span>Total Products:</span>
                <span>{cartItems?.length}</span>
              </div>
              <div className="summary-item1">
                <span>Subtotal:</span>
                <span>
                  ₹
                  {cartItems?.reduce(
                    (total, item) => total + item.offerPrice * item.quantity,
                    0
                  )}
                </span>
              </div>
              <div className="summary-item">
                <span>Tax:</span>
                <span>₹0</span>
              </div>
              <div className="summary-item total">
                <span>TOTAL:</span>
                <span>
                  ₹
                  {cartItems?.reduce(
                    (total, item) => total + item.offerPrice * item.quantity,
                    0
                  )}
                </span>
              </div>
              <button onClick={handleCheckout} className="checkout-button">
                Proceed to Checkout
              </button>
            </div>
          </div>
          <Link to="/e-commerce" className="continue-shopping1">
            Continue
          </Link>
        </>
      )}
    </div>
  );
};

export default Cart;
